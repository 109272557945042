<template>
  <div
    class="wdp__wrapper"
  >
    <div class="wdp__header__wrapper">
      <div>
        <h6
          class="wdp__header normal-semi noselect"
        >
          {{ today.format('MMMM YYYY') }}
        </h6>
        <p class="small small-medium">
          {{ today.startOf('month').format('D') }} - {{ today.endOf('month').format('D MMM') }} {{ today.format('YYYY') }}
        </p>
      </div>
      <div class="wbp_action__wrapper">
        <div
          class="wbp_today__wrapper"
          @click="setDayToToday()"
        >
          <h6>
            {{ $t('Components.BackToOffice.today') }}
          </h6>
        </div>
        <div class="wdp__chevrons-wrapper noselect">
          <div
            class="wdp__chevron-wrapper"
            @click="prevMonthBlock()"
          >
            <icon
              icon="chevron-left"
              class="wdp__chevron"
            />
          </div>
          <div
            class="wdp__chevron-wrapper"
            :class="{'isDisabled': list && list.length >= 4 && moment().diff(list[4], 'months') <= 0}"
            @click="nextMonthBlock()"
          >
            <icon
              icon="chevron-right"
              class="wdp__chevron"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="wdp__day-options--flex-container"
    >
      <div
        class="wdp__dates-holder--wrapper"
      >
        <div
          v-for="(m, index) in list"
          :key="index"
          class="wdp__date-option--wrapper noselect"
          @click="changeMonth(m)"
        >
          <div class="wdp__day-container">
            <h6 class="secondary normal-medium">
              {{ m.format('YYYY') }}
            </h6>
          </div>
          <div class="wdp__date-container">
            <h5 class="x-big-semi">
              {{ m.format('MMM') }}
            </h5>
          </div>
          <div
            class="wdp__day-selected--mark"
            :class="{'selected': today.month() === m.month() && today.year() === m.year(),
                     'single-digit-day': false,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    month: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    today() {
      return moment(`${this.month}-${this.year}`, 'M-YYYY');
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    moment() {
      return moment();
    },
    async createMonth() {
      await this.$emit('list', [
        moment(this.today).subtract(4, 'month').startOf('month'),
        moment(this.today).subtract(3, 'month').startOf('month'),
        moment(this.today).subtract(2, 'month').startOf('month'),
        moment(this.today).subtract(1, 'month').startOf('month'),
        moment(this.today).startOf('month'),
      ]);
    },
    async nextMonthBlock() {
      if (this.list && this.list.length >= 4 && moment().diff(this.list[4], 'months') > 0) {
        const m = moment(this.list[4].add(5, 'months'));
        await this.changeMonth(m);
        await this.createMonth();
        await this.changeMonth(this.list[0]);
      }
    },
    async prevMonthBlock() {
      if (this.list && this.list.length > 0) {
        const m = moment(this.list[0].subtract(1, 'months'));
        await this.changeMonth(m);
        await this.createMonth();
        await this.changeMonth(this.list[4]);
      }
    },
    async setDayToToday() {
      await this.changeMonth(moment());
      await this.createMonth();
    },
    async changeMonth(m) {
      await this.$emit('month', m.month() + 1);
      await this.$emit('year', m.year());
    },
  },
};

</script>

<style lang="scss" scoped>

@import '@/scss/breakpoints.scss';

.wdp__wrapper{
  margin: 12px 0;
}

.wdp__header {
  color: var(--primary-font-color);
  margin: auto 0;
}

.wdp__header__wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
  p{
    margin: 0;
  }
}

.wdp__dates-holder--wrapper {
  display: flex;
  justify-content: space-between;
}
.wbp_action__wrapper{
  gap: 1rem;
  display: flex;
  justify-content: flex-end;
}
.wbp_today__wrapper{
  height: 2rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  padding:  0.2rem 1rem;
  display: flex;
  &:hover{
    cursor: pointer;
  }
  h6{
  color: var(--primary-font-color);
  margin: auto 0;
  }
}

.wdp__chevrons-wrapper{
  display: flex;
  justify-content: space-between;
  gap: 0.3rem;
  svg{
    width: 100%;
  }
}
.wdp__chevron-wrapper {
  color: var(--primary-font-color);
  border: 1px solid var(--border-color);
  padding: 0.2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
  &.isDisabled {
    opacity: 0.5;
    cursor: default;
  }
}
.wdp__date-option--wrapper {
  position: relative;
  padding: 0 1.15rem 0 1.15rem;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    h5, h6, h4 {
      opacity: 0.4;
    }
    &:hover {
      cursor: default;
    }
  }

  h5, h6, h4 {
    margin: 0;
    font-variant-numeric: tabular-nums;
    text-align: center;
    opacity: 1;
  }
}

.wdp__day-selected--mark {
  height: 0;
  width: 2.5rem;
  background-color: var(--primary-color);
  position: absolute;
  &.selected.single-digit-day {
    transform: translateX(-0.52rem);
    height: 0.4rem;
  }
  &.selected {
    transform: translateX(0.1rem);
    height: 0.4rem;
  }
}

.wdp__notfication-container{
    display: flex;
    justify-content: space-between;
    gap: 0.2rem;
    position:absolute;
    transform: translateX(1.5rem) translateY(-0.5rem);
  .wdp__booking-notification {
    height: 10px;
    width: 10px;
    transition: ease width, height 0.2s;
    border-radius: 50%;
    &.resource{
        background-color: var(--primary-color);
        border-radius: 50%;
    }
    &.space{
      background-color: var(--space-color);
      border-radius: 15%;
    }
}

}
</style>
