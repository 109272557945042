<template>
  <div
    class="filter-item_zonetype"
  >
    <svg-icon :src="icon" />
    <p> {{ title }} </p>
    <div
      class="icon-close_container"
      @click="$emit('close')"
    >
      <svg-icon
        src="/icons/close_icon.svg"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    icon: {
      type: String,
      required: true,
      default: '/icons/location.svg',
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
.filter-item_zonetype{
  background-color: #f7e9e0;
  display: flex;
  padding: 0.5rem 0.75rem;
  gap:0.5rem;
  border-radius: 0.5rem;
  p{
    color: #ed5106;
    margin: auto
  }
  svg{
    size: 1.2rem;
    height: 1.2rem;
    width: 1.2rem;
    vertical-align: text-top;
    fill: #ed5106;
    color: #ed5106;
    &:hover{
      cursor: pointer;
    }
  }
}
</style>
