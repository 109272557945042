<template>
  <div>
    <div
      class="filter-button"
      :class="!showText ? 'fill' : ''"
      @click="$emit('click')"
    >
      <svg-icon src="/icons/filter_icon.svg" />
      <h6 v-if="showText">
        {{ $t('Views.Book.filters') }}
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showText: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.filter-button{
  display: flex;
  justify-content: right;
  border-radius: 0.5rem;
  background-color: var(--primary-color-light);
  gap:0.5rem;
  padding: 0.5rem 0.75rem;
  &.fill{
    background-color: var(--primary-color);
    h6{
      color: var(--primary-color-light);
    }
    svg{
    fill: white;
    color: white;
    }
  }
  &:hover{
    cursor: pointer;
  }
  h6{
    display: flex;
    color: var(--primary-color);
    margin: auto 0;
  }
  svg{
    height: 1.2rem;
    vertical-align: text-top;
    fill: var(--primary-color);
    color: var(--primary-color);
  }
}
</style>
