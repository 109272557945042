<template>
  <div class="content">
    <Header
      :back="true"
      :route="`/report/${$route.params.page}`"
      class="flex"
      text="Back"
    >
      <h5 class="header-title">
        {{ $t('Views.Report.EquipmentFilter') }}
      </h5>
    </Header>
    <Card>
      {{ $t('Views.Report.EquipmentFilter') }}
    </Card>
  </div>
</template>

<script>
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import ReportModel from '@/singletons/report.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';
// import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';

export default {
  components: {
    Header,
    Card,
    // RoundResourceIcon,
  },
  data() {
    return {
      ReportModel,
      CompanyState,
    };
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  mounted() {
    // console.log('PowerBI', ReportModel);
  },
  methods: {
  },
};

</script>

<style lang="scss" scoped>

.router-link-exact-active
.settings_link--container {
  background: #def3e7;
  border-radius: 0.5rem;
}

.settings_link--container {
  margin: 0.5rem 0;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  &:hover{
    text-decoration: none !important;
    cursor: pointer;
  }
  .content-container{
    display: flex;
    gap:1rem;
  }
}

.content-container.skeleton {
  width: 100%;

  .round-resource-icon__wrapper {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    border-color: #eee;

    .icon {
      &.settings svg {
        color: var(--secondary-font-color);
        fill: var(--secondary-font-color);
      }
    }
  }
}

.setting_link--text{
  margin-top: auto;
  margin-bottom: auto;

  &.skeleton {
    height: 100%;
    flex-grow: 1;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
}

.settings_icon--right {
  margin: auto 0;
  font-size: 1.3rem;
}

.custom-area {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

</style>
