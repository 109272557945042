<template>
  <div>
    <div
      class="filter-button_all"
    >
      <svg-icon src="/icons/office.svg" />
      <h6>
        {{ $t('Views.Report.LocationFilterAll') }}
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
};
</script>

<style lang="scss">
.filter-button_all{
  display: flex;
  justify-content: right;
  border-radius: 0.5rem;
  background-color: var(--background-kebab-color);
  gap:0.5rem;
  padding: 0.5rem 0.75rem;
  &.fill{
    background-color: var(--background-kebab-color);
    h6{
      color: var(--secondary-color-light);
    }
    svg{
    fill: white;
    color: white;
    }
  }
  &:hover{
    cursor: default;
  }
  h6{
    display: flex;
    color: var(--secondary-color-light);
    margin: auto 0;
  }
  svg{
    height: 1.2rem;
    vertical-align: text-top;
    fill: var(--secondary-color-light);
    color: var(--secondary-color-light);
  }
}
</style>
