<template>
  <div>
    <Header
      :back="true"
      :route="`/report/${$route.params.page}/filters`"
      class="flex"
      :text="$t('Views.Report.Filters')"
      :title="$t('Views.Report.LocationFilter')"
    >
      <h5 class="header-title">
        {{ $t('Views.Report.LocationFilter') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card position="fill">
        <div
          v-for="company in ReportModel.companyList"
          :key="company.Cid"
        >
          <div

            class="settings_link--container"
            @click="setCompany(company)"
          >
            <div class="content-container">
              <RoundResourceIcon
                src="/icons/office.svg"
                type="settings reverse"
              />
              <h6 class="setting_link--text">
                {{ company.Name }}
              </h6>
            </div>
            <div
              v-if="ReportModel.selectedCompanies.indexOf(company.Cid) > -1"
            >
              <RoundResourceIcon
                src="/icons/check-solid.svg"
                type="settings"
              />
            </div>
            <div v-else>
              <RoundResourceIcon
                type="settings reverse"
              />
            </div>
          </div>
          <div
            v-for="floor in ReportModel.floorList[company.Cid]"
            :key="floor.Zid"
            class="settings_link--container margin-left-indent"
            @click="setFloor(floor)"
          >
            <div class="content-container">
              <RoundResourceIcon
                src="/icons/location.svg"
                type="settings reverse"
              />
              <h6 class="setting_link--text">
                {{ floor.Name }}
              </h6>
            </div>
            <div
              v-if="ReportModel.selectedFloors.indexOf(floor.Zid) > -1"
            >
              <RoundResourceIcon
                src="/icons/check-solid.svg"
                type="settings"
              />
            </div>
            <div v-else>
              <RoundResourceIcon
                type="settings reverse"
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import ReportModel from '@/singletons/report.state.singleton';
import ReportService from '@/Services/Report/report.service';
import CompanyState from '@/singletons/company.state.singleton';

export default {
  components: {
    Header,
    Card,
    RoundResourceIcon,
  },
  data() {
    return {
      ReportModel,
      CompanyState,
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    setCompany(company) {
      const cIndex = ReportModel.selectedCompanies.indexOf(company.Cid);
      if (cIndex > -1) {
        ReportModel.floorList[company.Cid].forEach((f) => {
          const fIndex = ReportModel.selectedFloors.indexOf(f.Zid);
          if (fIndex > -1) {
            ReportModel.selectedFloors.splice(fIndex, 1);
          }
        });
        ReportModel.selectedCompanies.splice(cIndex, 1);
      } else {
        ReportModel.selectedCompanies.push(company.Cid);
        ReportModel.floorList[company.Cid].forEach((f) => {
          if (ReportModel.selectedFloors.indexOf(f.Zid) === -1) {
            ReportModel.selectedFloors.push(f.Zid);
          }
        });
      }
      ReportModel.allFloorsSelected = false;
      ReportService.applyFilters();
    },
    setFloor(floor) {
      const fIndex = ReportModel.selectedFloors.indexOf(floor.Zid);
      if (fIndex === -1) {
        ReportModel.selectedFloors.push(floor.Zid);
      } else {
        ReportModel.selectedFloors.splice(fIndex, 1);
      }
      ReportModel.allFloorsSelected = false;
      ReportService.applyFilters();
    },
  },
};

</script>

<style lang="scss" scoped>

.router-link-exact-active
.settings_link--container {
  background: #def3e7;
  border-radius: 0.5rem;
}

.settings_link--container {
  margin: 0.3rem 0;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  &:hover{
    text-decoration: none !important;
    cursor: pointer;
  }
  .content-container{
    display: flex;
    gap:1rem;
  }
}

.settings_link--container.active {
  background: #def3e7;
  border-radius: 0.5rem;
}

.settings_link--container.margin-left-indent {
  margin-left:1rem;
}

.setting_link--text{
  margin-top: auto;
  margin-bottom: auto;
}

.settings_icon--right {
  margin: auto 0;
  font-size: 1.3rem;
}

.custom-area {
  display: flex;
  align-items: center;
  gap: 1rem;
}

</style>
