<template>
  <div>
    <Header
      :back="true"
      :route="`/report/${$route.params.page}/filters`"
      class="flex"
      :text="$t('Views.Report.Filters')"
      :title="$t('Views.Report.ResourceTypeFilter')"
    >
      <h5 class="header-title">
        {{ $t('Views.Report.ResourceTypeFilter') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card position="fill">
        <div
          v-for="zonetype in zoneTypeList"
          :key="zonetype.Constant"
        >
          <div

            class="settings_link--container"
            @click="setZoneType(zonetype.ZoneType)"
          >
            <div class="content-container">
              <RoundResourceIcon
                :src="zonetype.Icon || '/icons/location.svg'"
                type="settings reverse"
              />
              <h6 class="setting_link--text">
                {{ zonetype.Name }}
              </h6>
            </div>
            <div
              v-if="ReportModel.selectedZoneTypes.indexOf(zonetype.ZoneType) > -1"
            >
              <RoundResourceIcon
                src="/icons/check-solid.svg"
                type="settings"
              />
            </div>
            <div v-else>
              <RoundResourceIcon
                type="settings reverse"
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import ReportModel from '@/singletons/report.state.singleton';
import ReportService from '@/Services/Report/report.service';
import CompanyState from '@/singletons/company.state.singleton';

export default {
  components: {
    Header,
    Card,
    RoundResourceIcon,
  },
  data() {
    return {
      ReportModel,
      CompanyState,
    };
  },
  computed: {
    zoneTypeList() {
      return Object.values(ReportModel.zoneTypeList)
        .sort((a, b) => {
          if (a.Name > b.Name) return 1;
          if (a.Name < b.Name) return -1;
          return 0;
        });
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    setZoneType(ztype) {
      const fIndex = ReportModel.selectedZoneTypes.indexOf(ztype);
      if (fIndex === -1) {
        ReportModel.selectedZoneTypes.push(ztype);
      } else {
        ReportModel.selectedZoneTypes.splice(fIndex, 1);
      }
      ReportService.applyFilters();
    },
  },
};

</script>

<style lang="scss" scoped>

.settings_link--container {
  margin: 0.3rem 0;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  &:hover{
    text-decoration: none !important;
    cursor: pointer;
  }
  .content-container{
    display: flex;
    gap:1rem;
  }
}

.settings_link--container.active {
  background: #def3e7;
  border-radius: 0.5rem;
}

.settings_link--container.margin-left-indent {
  margin-left:1rem;
}

.setting_link--text{
  margin-top: auto;
  margin-bottom: auto;
}

.settings_icon--right {
  margin: auto 0;
  font-size: 1.3rem;
}

.custom-area {
  display: flex;
  align-items: center;
  gap: 1rem;
}

</style>
