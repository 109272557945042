<template>
  <div>
    <Header
      :back="true"
      :route="`/report/${$route.params.page}`"
      class="flex"
      :text="$t('Views.Report.Title')"
      :title="$t('Views.Report.Timeframe')"
    >
      <h5 class="header-title">
        {{ $t('Views.Report.Timeframe') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card position="fill">
        <div
          v-for="key in Object.keys(timeframeList)"
          :key="key"
          class="settings_link--container"
          @click="setTimeframe(key)"
        >
          <div class="content-container">
            <RoundResourceIcon
              src="/icons/calendar_date_icon.svg"
              type="settings reverse"
            />
            <h6 class="setting_link--text">
              {{ $t(timeframeList[key]) }}
            </h6>
          </div>
          <div
            v-if="ReportModel.selectedPeriod === key"
          >
            <RoundResourceIcon
              src="/icons/check-solid.svg"
              type="settings"
            />
          </div>
          <div v-else>
            <RoundResourceIcon
              type="settings reverse"
            />
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import ReportModel from '@/singletons/report.state.singleton';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';

export default {
  components: {
    Header,
    Card,
    RoundResourceIcon,
  },
  data() {
    return {
      ReportModel,
      timeframeList: {
        WEEK: 'Views.Report.WeeklyReports',
        MONTH: 'Views.Report.MonthlyReports',
      },
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  destroyed() {
    let activePage = null;
    switch (ReportModel.selectedPeriod) {
      case 'MONTH':
        activePage = ReportModel.pagesMonthly[0]?.name;
        break;
      case 'WEEK':
      default:
        activePage = ReportModel.pagesWeekly[0]?.name;
    }
    if (activePage) {
      this.$router.replace(`/report/${activePage}`);
    }
  },
  methods: {
    setTimeframe(val) {
      ReportModel.selectedPeriod = val;
    },
  },
};

</script>

<style lang="scss" scoped>

.settings_link--container {
  margin: 0.5rem 0;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  &:hover{
    text-decoration: none !important;
    cursor: pointer;
  }
  .content-container{
    display: flex;
    gap:1rem;
  }
}

.settings_link--container.active {
  background: #def3e7;
  border-radius: 0.5rem;
}

.setting_link--text{
  margin-top: auto;
  margin-bottom: auto;
}

.settings_icon--right {
  margin: auto 0;
  font-size: 1.3rem;
}

.custom-area {
  display: flex;
  align-items: center;
  gap: 1rem;
}

</style>
