<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="content">
    <Header
      class="flex"
      :title="$t('Views.Report.Title')"
    >
      <h5 class="header-title">
        {{ $t('Views.Report.Title') }}
      </h5>
    </Header>
    <template v-if="ReportModel.state === REPORT_STATE_LOADED">
      <div class="scrollable">
        <Card position="fill">
          <WeekDateSelector
            v-if="ReportModel.selectedPeriod === 'WEEK'"
            :week="selectedWeek"
            :year="selectedYear"
            :list="weekList"
            @week="selectedWeek = arguments[0]"
            @year="selectedYear = arguments[0]"
            @list="weekList = arguments[0]"
          />
          <MonthDateSelector
            v-if="ReportModel.selectedPeriod === 'MONTH'"
            :month="selectedMonth"
            :year="selectedYear"
            :list="monthList"
            @month="selectedMonth = arguments[0]"
            @year="selectedYear = arguments[0]"
            @list="monthList = arguments[0]"
          />
        </Card>
        <Card position="fill">
          <div class="filter-resource_container">
            <TimeframeButton
              :show-text="true"
              :text="timeframeText"
              @click="routerPush(`${$router.currentRoute.path}/timeframe`)"
            />
            <FilterButton
              :show-text="true"
              @click="routerPush(`${$router.currentRoute.path}/filters`)"
            />
            <FilterLocation
              v-for="floor in ReportModel.selectedFloors"
              v-if="!ReportModel.allFloorsSelected"
              :key="floor"
              :title="locationName(floor)"
              @close="removeLocationFilter(floor)"
            />
            <FilterZoneType
              v-for="zoneType in ReportModel.selectedZoneTypes"
              :key="zoneType"
              :title="ReportModel.zoneTypeList[zoneType.toUpperCase()]?.Name"
              :icon="ReportModel.zoneTypeList[zoneType.toUpperCase()]?.Icon"
              @close="removeZoneTypeFilter(zoneType)"
            />
            <FilterZone
              v-for="zone in ReportModel.selectedZones"
              :key="zone"
              :title="ReportModel.zoneObject[zone].Name"
              :zonetype="ReportModel.zoneObject[zone].Type"
              @close="removeZoneFilter(zone)"
            />
            <FilterLocationAll v-if="ReportModel.allFloorsSelected" />
          </div>
          <router-link
            v-for="page in pages"
            :key="page.name"
            :to="`/report/${page.name}`"
          >
            <div
              class="settings_link--container"
              :class="page.isActive ? 'active': null"
            >
              <div class="content-container">
                <RoundResourceIcon
                  :src="`${page.displayName === 'Filters' ? '/icons/filter_icon.svg' : `/icons/${iconMap[page.name]}.svg`}`"
                  type="settings reverse"
                />
                <h6 class="setting_link--text">
                  {{ page.displayName }}
                </h6>
              </div>
            </div>
          </router-link>
        </Card>
      </div>
    </template>

    <template v-else-if="ReportModel.state === REPORT_STATE_ERROR">
      <Card>
        <div>Error: Could not load report.</div>
      </Card>
    </template>

    <!-- Skeleton Loading, TODO: // refactor into own component / class with :empty pseudo -->
    <template v-else>
      <Card>
        <div class="settings_link--container skeleton">
          <div class="content-container skeleton">
            <RoundResourceIcon
              class="skeleton"
              :src="'/icons/filter_icon.svg'"
              type="settings reverse"
            />
            <h6 class="setting_link--text skeleton" />
          </div>
        </div>
        <div
          class="settings_link--container skeleton"
        >
          <div class="content-container skeleton">
            <RoundResourceIcon
              class="skeleton"
              :src="'/icons/statistics.svg'"
              type="settings reverse"
            />
            <h6 class="setting_link--text skeleton" />
          </div>
        </div>
      </Card>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import WeekDateSelector from '@/components/weeklydateselector/week.dateselector.vue';
import MonthDateSelector from '@/components/weeklydateselector/month.dateselector.vue';
import ReportModel, { REPORT_STATE_LOADING, REPORT_STATE_LOADED, REPORT_STATE_ERROR } from '@/singletons/report.state.singleton';
import ReportService from '@/Services/Report/report.service';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import FilterButton from './components/filter.button.vue';
import FilterLocationAll from './components/filter.location.all.vue';
import FilterLocation from './components/filter.location.vue';
import FilterZoneType from './components/filter.zonetype.vue';
import FilterZone from './components/filter.zone.vue';
import TimeframeButton from './components/timeframe.button.vue';

const CHART_ICON_MAP = {
  'week-average-usage': 'system_rep_average',
  'month-average-usage': 'system_rep_average',
  'week-top-booking': 'system_utilization',
  'month-top-booking': 'system_utilization',
  'week-top-utilization': 'system_rep_booking',
  'month-top-utilization': 'system_rep_booking',
};

export default {
  components: {
    Header,
    Card,
    FilterButton,
    FilterLocationAll,
    FilterLocation,
    TimeframeButton,
    RoundResourceIcon,
    WeekDateSelector,
    MonthDateSelector,
    FilterZoneType,
    FilterZone,
  },
  data() {
    return {
      moment,
      ReportModel,
      ReportService,

      REPORT_STATE_LOADING,
      REPORT_STATE_LOADED,
      REPORT_STATE_ERROR,

      selectedYear: 0,
      selectedMonth: 0,
      selectedWeek: 0,
      weekList: [],
      monthList: [],
      selectedZoneType: [],
      iconMap: CHART_ICON_MAP,
    };
  },
  computed: {
    pages() {
      switch (ReportModel.selectedPeriod) {
        case 'YEAR':
        case 'QUARTER':
        case 'MONTH':
          return ReportModel.pagesMonthly;
        case 'WEEK':
        default:
          return ReportModel.pagesWeekly;
      }
    },
    timeframeText() {
      switch (ReportModel.selectedPeriod) {
        case 'YEAR':
          return this.$t('Views.Report.YearlyReports');
        case 'QUARTER':
          return this.$t('Views.Report.QuarterlyReports');
        case 'MONTH':
          return this.$t('Views.Report.MonthlyReports');
        case 'WEEK':
        default:
          return this.$t('Views.Report.WeeklyReports');
      }
    },
  },
  watch: {
    selectedWeek(newWeek, oldWeek) {
      if (newWeek !== oldWeek) {
        ReportModel.selectedWeek = newWeek;
        ReportService.applyFilters();
      }
    },
    selectedMonth(newMonth, oldMonth) {
      if (newMonth !== oldMonth) {
        ReportModel.selectedMonth = newMonth;
        ReportService.applyFilters();
      }
    },
    selectedYear(newYear, oldYear) {
      if (newYear !== oldYear) {
        ReportModel.selectedYear = newYear;
        ReportService.applyFilters();
      }
    },
    weekList(newList) {
      if (newList) {
        ReportModel.weekList = newList;
      }
    },
    monthList(newList) {
      if (newList) {
        ReportModel.monthList = newList;
      }
    },
  },
  created() {
    this.selectedYear = ReportModel.selectedYear;
    this.selectedWeek = ReportModel.selectedWeek;
    this.selectedMonth = ReportModel.selectedMonth;
    this.weekList = ReportModel.weekList;
    this.monthList = ReportModel.monthList;
  },
  mounted() {
    if (ReportModel.selectedFloors.length === 0) {
      ReportModel.selectedFloors = Object.keys(ReportModel.floorObject).map((f) => Number(f));
      ReportModel.selectedCompanies = Object.keys(ReportModel.companyObject).map((f) => Number(f));
      ReportModel.allFloorsSelected = true;
    }
  },
  methods: {
    locationName(floorId) {
      const f = ReportModel.floorObject[floorId];
      return `${f.Name}, ${ReportModel.companyObject[f.Parent].Name}`;
    },
    routerPush(url) {
      this.$router.push({ path: url });
    },
    removeLocationFilter(floor) {
      const i = ReportModel.selectedFloors && ReportModel.selectedFloors.indexOf(floor);
      if (i > -1) {
        ReportModel.selectedFloors.splice(i, 1);
      }
      if (ReportModel.selectedFloors.length === 0) {
        ReportModel.selectedFloors = Object.keys(ReportModel.floorObject).map((f) => Number(f));
        ReportModel.selectedCompanies = Object.keys(ReportModel.companyObject).map((f) => Number(f));
        ReportModel.allFloorsSelected = true;
      }
      ReportService.applyFilters();
    },
    removeZoneTypeFilter(zoneType) {
      const i = ReportModel.selectedZoneTypes && ReportModel.selectedZoneTypes.indexOf(zoneType);
      if (i > -1) {
        ReportModel.selectedZoneTypes.splice(i, 1);
      }
      ReportService.applyFilters();
    },
    removeZoneFilter(zone) {
      const i = ReportModel.selectedZones && ReportModel.selectedZones.indexOf(zone.toString());
      if (i > -1) {
        ReportModel.selectedZones.splice(i, 1);
      }
      ReportService.applyFilters();
    },
    async readBookmark() {
      // const bookmark = await ReportModel.report.bookmarksManager.capture({
      //   allPages: true,
      // });
      // this.bookmarkValue = bookmark.state;
    },
    async restoreBookmark() {
      // await ReportModel.report.bookmarksManager.applyState(this.bookmarkValue);
    },
  },
};

</script>

<style lang="scss" scoped>

.router-link-exact-active
.settings_link--container {
  background: #def3e7;
  border-radius: 0.5rem;
}

.settings_link--container {
  margin: 0.5rem 0;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  &:hover{
    text-decoration: none !important;
    cursor: pointer;
  }
  .content-container{
    display: flex;
    gap:1rem;
  }
}

.content-container.skeleton {
  width: 100%;

  .round-resource-icon__wrapper {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    border-color: #eee;

    .icon {
      &.settings svg {
        color: var(--secondary-font-color);
        fill: var(--secondary-font-color);
      }
    }
  }
}

.setting_link--text{
  margin-top: auto;
  margin-bottom: auto;

  &.skeleton {
    height: 100%;
    flex-grow: 1;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    cursor: default;
  }
}

.settings_icon--right {
  margin: auto 0;
  font-size: 1.3rem;
}

.custom-area {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.filter-resource_container{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 0.5rem;
  flex-direction: row-reverse;
  margin-bottom: 1rem;
}

</style>
