<template>
  <div
    class="wdp__wrapper"
  >
    <div class="wdp__header__wrapper">
      <div>
        <h6
          class="wdp__header normal-semi noselect"
        >
          {{ today.startOf('isoWeek').format('D MMM') }} - {{ today.add(4, 'days').format('D MMM') }} {{ today.startOf('isoWeek').format('YYYY') }}
        </h6>
        <p class="small small-medium">
          {{ $t('Components.BackToOffice.week') }} {{ today.isoWeek() }}
        </p>
      </div>
      <div class="wbp_action__wrapper">
        <div
          class="wbp_today__wrapper"
          @click="setDayToToday()"
        >
          <h6>
            {{ $t('Components.BackToOffice.today') }}
          </h6>
        </div>
        <div class="wdp__chevrons-wrapper noselect">
          <div
            class="wdp__chevron-wrapper"
            @click="prevWeekBlock()"
          >
            <icon
              icon="chevron-left"
              class="wdp__chevron"
            />
          </div>
          <div
            class="wdp__chevron-wrapper"
            :class="{'isDisabled': list && list.length >= 4 && moment().diff(list[4], 'weeks') <= 0}"
            @click="nextWeekBlock()"
          >
            <icon
              icon="chevron-right"
              class="wdp__chevron"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="wdp__day-options--flex-container"
    >
      <div
        class="wdp__dates-holder--wrapper"
      >
        <div
          v-for="(w, index) in list"
          :key="index"
          class="wdp__date-option--wrapper noselect"
          @click="changeWeek(w)"
        >
          <div class="wdp__day-container">
            <h6 class="secondary normal-medium">
              {{ $t('Views.Report.W') }}
            </h6>
          </div>
          <div class="wdp__date-container">
            <h5 class="x-big-semi">
              {{ w.isoWeek() }}
            </h5>
          </div>
          <div
            class="wdp__day-selected--mark"
            :class="{'selected': today.isoWeek() === w.isoWeek() && today.year() === w.year(),
                     'single-digit-day': w.isoWeek().toString().length === 1,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    week: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    today() {
      return moment(`${this.week}-${this.year}`, 'W-YYYY');
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    moment() {
      return moment();
    },
    async createWeek() {
      await this.$emit('list', [
        moment(this.today).subtract(4, 'weeks').startOf('isoWeek'),
        moment(this.today).subtract(3, 'weeks').startOf('isoWeek'),
        moment(this.today).subtract(2, 'weeks').startOf('isoWeek'),
        moment(this.today).subtract(1, 'weeks').startOf('isoWeek'),
        moment(this.today).startOf('isoWeek'),
      ]);
    },
    async nextWeekBlock() {
      if (this.list && this.list.length >= 4 && moment().diff(this.list[4], 'weeks') > 0) {
        const w = moment(this.list[4].add(5, 'weeks'));
        await this.changeWeek(w);
        await this.createWeek();
        await this.changeWeek(this.list[0]);
      }
    },
    async prevWeekBlock() {
      if (this.list && this.list.length > 0) {
        const w = moment(this.list[0].subtract(1, 'weeks'));
        await this.changeWeek(w);
        await this.createWeek();
        await this.changeWeek(this.list[4]);
      }
    },
    async setDayToToday() {
      await this.changeWeek(moment());
      await this.createWeek();
    },
    async changeWeek(w) {
      await this.$emit('week', w.isoWeek());
      await this.$emit('year', w.year());
    },
  },
};

</script>

<style lang="scss" scoped>

@import '@/scss/breakpoints.scss';

.wdp__wrapper{
  margin: 12px 0;
}

.wdp__header {
  color: var(--primary-font-color);
  margin: auto 0;
}

.wdp__header__wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
  p{
    margin: 0;
  }
}

.wdp__dates-holder--wrapper {
  display: flex;
  justify-content: space-between;
}
.wbp_action__wrapper{
  gap: 1rem;
  display: flex;
  justify-content: flex-end;
}
.wbp_today__wrapper{
  height: 2rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  padding:  0.2rem 1rem;
  display: flex;
  &:hover{
    cursor: pointer;
  }
  h6{
  color: var(--primary-font-color);
  margin: auto 0;
  }
}

.wdp__chevrons-wrapper{
  display: flex;
  justify-content: space-between;
  gap: 0.3rem;
  svg{
    width: 100%;
  }
}
.wdp__chevron-wrapper {
  color: var(--primary-font-color);
  border: 1px solid var(--border-color);
  padding: 0.2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
  &.isDisabled {
    opacity: 0.5;
    cursor: default;
  }
}
.wdp__date-option--wrapper {
  position: relative;
  padding: 0 1.15rem 0 1.15rem;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    h5, h6, h4 {
      opacity: 0.4;
    }
    &:hover {
      cursor: default;
    }
  }

  h5, h6, h4 {
    margin: 0;
    font-variant-numeric: tabular-nums;
    text-align: center;
    opacity: 1;
  }
}

.wdp__day-selected--mark {
  height: 0;
  width: 2rem;
  background-color: var(--primary-color);
  position: absolute;
  &.selected.single-digit-day {
    transform: translateX(-0.52rem);
    height: 0.4rem;
  }
  &.selected {
    transform: translateX(-0.1rem);
    height: 0.4rem;
  }
}

.wdp__notfication-container{
    display: flex;
    justify-content: space-between;
    gap: 0.2rem;
    position:absolute;
    transform: translateX(1.5rem) translateY(-0.5rem);
  .wdp__booking-notification {
    height: 10px;
    width: 10px;
    transition: ease width, height 0.2s;
    border-radius: 50%;
    &.resource{
        background-color: var(--primary-color);
        border-radius: 50%;
    }
    &.space{
      background-color: var(--space-color);
      border-radius: 15%;
    }
}

}
</style>
