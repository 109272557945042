/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import ReportModel, {
  REPORT_STATE_LOADING, REPORT_STATE_LOADED, REPORT_STATE_ERROR, REPORT_STATE_UPDATED_NEEDED,
} from '@/singletons/report.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';
import { getPowerBIEmbedToken, getZones } from '@/util/api';
import apiClient from '../../util/api/api.interceptor';

const baseUrl = process.env.VUE_APP_BASE_URL;

class ReportService {
  static setReportState(state = REPORT_STATE_LOADING) {
    ReportModel.state = state;
  }

  static getReportState() {
    return ReportModel.state;
  }

  static async embedReport(target) {
    try {
      await CompanyState.finnishedLoading;
      if (CompanyState && CompanyState.companiesInRealm && CompanyState.zones && ReportModel.companyList.length === 0) {
        CompanyState.companiesInRealm.forEach(async (c) => {
          const zonesInCompany = CompanyState.zones.filter((z) => z.Type === 'FLOOR' && z.Parent === c.Cid);
          zonesInCompany.sort((a, b) => {
            if (a.Name < b.Name) {
              return -1;
            }
            if (a.Name > b.Name) {
              return 1;
            }
            return 0;
          });
          if (zonesInCompany.length > 0) {
            if (c.Root) {
              ReportModel.companyList.splice(0, 0, c);
            } else {
              ReportModel.companyList.push(c);
            }
            ReportModel.companyObject[c.Cid] = c;
            ReportModel.floorList[c.Cid] = zonesInCompany;
            ReportModel.selectedCompanies.push(c.Cid);
            zonesInCompany.forEach((z) => {
              ReportModel.selectedFloors.push(z.Zid);
              ReportModel.floorObject[z.Zid] = z;
            });
          }
        });
        Object.keys(ReportModel.companyObject).forEach(async (cid) => {
          const { data } = await getZones(cid);
          data.forEach((zone) => {
            const c = zone.Type.charAt(0).toUpperCase() + zone.Type.toLowerCase().slice(1);
            ReportModel.zoneObject[zone.Zid] = zone;
            ReportModel.zoneObject[zone.Zid].ZoneType = c;
            if (zone.ParentZone && !(zone.Type in ReportModel.zoneTypeList)) {
              const zoneObj = CompanyState.zoneTypes?.filter((zt) => zt.Constant === zone.Type)[0];
              ReportModel.zoneTypeList[zone.Type] = {
                ZoneType: c,
                Type: zone.Type,
                Icon: zoneObj?.Icon,
                Name: zoneObj?.Name,
              };
            }
          });
        });
      }
    } catch (err) {
      ReportService.setReportState(REPORT_STATE_ERROR);
      ReportModel.report = {
        isError: true,
        error: err,
      };
    }
    return ReportModel.report;
  }

  static async setPage(pageId) {
    ReportModel.pagesWeekly.forEach((page) => {
      if (page.name === pageId) {
        page.isActive = true;
        ReportModel.currentPage = page;
      }
    });
    ReportModel.pagesMonthly.forEach((page) => {
      if (page.name === pageId) {
        page.isActive = true;
        ReportModel.currentPage = page;
      }
    });
    await ReportService.applyFilters();
  }

  static async getActivePage() {
    if (!ReportModel.currentPage && ReportModel.selectedPeriod === 'WEEK' && ReportModel.pagesWeekly.length > 0) {
      await ReportService.setPage(ReportModel.pagesWeekly[0].name);
    } else if (!ReportModel.currentPage && ReportModel.selectedPeriod === 'MONTH' && ReportModel.pagesMonthly.length > 0) {
      await ReportService.setPage(ReportModel.pagesMonthly[0].name);
    }
    return ReportModel.currentPage;
  }

  static getFilters() {
    const filtersArray = {};
    switch (ReportModel.selectedPeriod) {
      case 'MONTH':
        filtersArray.year = ReportModel.selectedYear.toString();
        filtersArray.month = ReportModel.selectedMonth.toString();
        break;
      case 'WEEK':
      default:
        filtersArray.year = ReportModel.selectedYear.toString();
        filtersArray.week = ReportModel.selectedWeek.toString();
        break;
    }
    filtersArray.parentzonecode = ReportModel.selectedFloors;
    if (ReportModel.selectedZoneTypes.length > 0) {
      filtersArray.zonetypecode = ReportModel.selectedZoneTypes.map((type) => type.toUpperCase());
    }
    if (ReportModel.selectedZones.length > 0) {
      filtersArray.zonecode = ReportModel.selectedZones;
    }
    return filtersArray;
  }

  static async applyFilters(reloadZones = true) {
    // console.log('applyFilters', ReportModel.filterState);
    if (ReportModel.filterState > 0) {
      ReportModel.filterState = 2;
      return;
    }
    ReportModel.filterState = 1;
    const filtersArray = ReportService.getFilters();
    const req = await apiClient.post(`${baseUrl}/v1/admin/report/data`, filtersArray);
    if (req.status === 200) {
      ReportModel.reportData = req.data;
      // console.log('APPLY FILTERS', filtersArray, ReportModel.reportData);
      ReportService.setReportState(REPORT_STATE_LOADED);
      if (ReportModel.filterState > 1) {
        ReportModel.filterState = 0;
        ReportService.applyFilters();
      } else {
        ReportModel.filterState = 0;
      }
    } else {
      return ReportService.setReportState(REPORT_STATE_ERROR);
    }

    if (reloadZones && !ReportModel.filteredZonesLoading) {
      ReportModel.filteredZonesLoading = true;
      setTimeout(() => {
        ReportModel.filteredZones = Object.values(ReportModel.zoneObject)
          .filter((z) => ReportModel.selectedFloors.indexOf(z.ParentZone) > -1
            && (ReportModel.selectedZoneTypes.length === 0 || ReportModel.selectedZoneTypes.indexOf(z.ZoneType) > -1))
          .sort((a, b) => {
            if (a.Name > b.Name) return 1;
            if (a.Name < b.Name) return -1;
            return 0;
          });
        ReportModel.filteredZonesLoading = false;
      }, 1);
    }
  }

  static cleanup() {
    ReportModel.report = null;
    ReportModel.pages = [];
    ReportModel.filter = [];
    ReportModel.reportName = 'Report';
    ReportService.setReportState(REPORT_STATE_LOADING);
  }
}

export default ReportService;
