<template>
  <div class="content">
    <Header
      :back="true"
      :route="`/report/${$route.params.page}/filters`"
      class="flex"
      :text="$t('Views.Report.Filters')"
      :title="$t('Views.Report.ResourceFilter')"
    >
      <h5 class="header-title">
        {{ $t('Views.Report.ResourceFilter') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card
        v-if="ReportModel.filteredZonesLoading"
      >
        <router-link
          to=".."
          :disabled="true"
        >
          <div class="settings_link--container skeleton">
            <div class="content-container skeleton">
              <RoundResourceIcon
                class="skeleton"
                :src="'/icons/filter_icon.svg'"
                type="settings reverse"
              />
              <h6 class="setting_link--text skeleton" />
            </div>
          </div>
        </router-link>
      </Card>
      <Card
        v-else
        position="fill"
      >
        <div
          v-for="zone, key in filteredZones"
          :key="key"
        >
          <div
            class="settings_link--container"
            @click="setZone(zone.Zid)"
          >
            <div class="content-container">
              <RoundResourceIcon
                :src="CompanyState.zoneTypes?.filter((zt) => zt.Constant === zone.Type)[0]?.Icon || '/icons/location.svg'"
                type="settings reverse"
              />
              <h6 class="setting_link--text">
                {{ zone.Name }}
              </h6>
            </div>
            <div
              v-if="ReportModel.selectedZones.indexOf(zone.Zid.toString()) > -1"
            >
              <RoundResourceIcon
                src="/icons/check-solid.svg"
                type="settings"
              />
            </div>
            <div v-else>
              <RoundResourceIcon
                type="settings reverse"
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import ReportModel from '@/singletons/report.state.singleton';
import ReportService from '@/Services/Report/report.service';
import CompanyState from '@/singletons/company.state.singleton';

export default {
  components: {
    Header,
    Card,
    RoundResourceIcon,
  },
  data() {
    return {
      ReportModel,
      CompanyState,
    };
  },
  computed: {
    filteredZones() {
      return ReportModel.filteredZones;
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    setZone(zone) {
      const fIndex = ReportModel.selectedZones.indexOf(zone.toString());
      if (fIndex === -1) {
        ReportModel.selectedZones.push(zone.toString());
      } else {
        ReportModel.selectedZones.splice(fIndex, 1);
      }
      ReportService.applyFilters(false);
    },
  },
};

</script>

<style lang="scss" scoped>

.settings_link--container {
  margin: 0.3rem 0;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  &:hover{
    text-decoration: none !important;
    cursor: pointer;
  }
  .content-container{
    display: flex;
    gap:1rem;
  }
}

.content-container.skeleton {
  width: 100%;

  .round-resource-icon__wrapper {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    border-color: #eee;

    .icon {
      &.settings svg {
        color: var(--secondary-font-color);
        fill: var(--secondary-font-color);
      }
    }
  }
}

.settings_link--container.active {
  background: #def3e7;
  border-radius: 0.5rem;
}

.settings_link--container.margin-left-indent {
  margin-left:1rem;
}

.setting_link--text{
  margin-top: auto;
  margin-bottom: auto;

  &.skeleton {
    height: 100%;
    flex-grow: 1;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.settings_icon--right {
  margin: auto 0;
  font-size: 1.3rem;
}

.custom-area {
  display: flex;
  align-items: center;
  gap: 1rem;
}

</style>
