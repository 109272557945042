// import * as pbi from 'powerbi-client';
import moment from 'moment';
import CompanyState from '@/singletons/company.state.singleton';

export const REPORT_STATE_ERROR = 0;
export const REPORT_STATE_LOADING = 10;
export const REPORT_STATE_AUTHENTICATING = 20;
export const REPORT_STATE_UNAUTHORIZED = 30;
export const REPORT_STATE_AUTHENTICATED = 40;
export const REPORT_STATE_LOADED = 50;
export const REPORT_STATE_UPDATED_NEEDED = 60;

class PowerBiReportState {
  /** Power BI Report */
  report = null;

  reportData = null;

  reportName = 'Report';

  /** Which permissions the embedded report should have, currently read. */
  permissions = null; // pbi.models.Permissions.Read;

  /** Token type should always be embed */
  tokenType = null; // pbi.models.TokenType.Embed;

  /** Power BI Service object */
  powerbi = null; // new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

  /** PowerBI backend Embed Token Preloaded by ReportServiceJs */
  embedToken = null;

  /** Fit to client */
  pageView = 'fitToWidth';

  type = 'report';

  state = REPORT_STATE_LOADING;

  filterState = 0;

  currentPage = null;

  /** Current Report Filters, fetched from the report itself */
  companyId = CompanyState.realm.toString();

  companyList = [];

  companyObject = {};

  floorList = {};

  floorObject = {};

  zoneObject = {};

  zoneTypeList = {};

  filteredZones = [];

  filteredZonesLoading = false;

  selectedPeriod = 'WEEK';

  selectedYear = moment().year();

  selectedMonth = moment().month() + 1;

  selectedWeek = moment().isoWeek();

  weekList = [
    moment().subtract(4, 'weeks').startOf('isoWeek'),
    moment().subtract(3, 'weeks').startOf('isoWeek'),
    moment().subtract(2, 'weeks').startOf('isoWeek'),
    moment().subtract(1, 'weeks').startOf('isoWeek'),
    moment().startOf('isoWeek'),
  ];

  monthList = [
    moment().subtract(4, 'month').startOf('month'),
    moment().subtract(3, 'month').startOf('month'),
    moment().subtract(2, 'month').startOf('month'),
    moment().subtract(1, 'month').startOf('month'),
    moment(),
  ];

  allFloorsSelected = true;

  selectedCompanies = [];

  selectedFloors = [];

  selectedZoneTypes = [];

  selectedZones = [];

  pages = [];

  pagesWeekly = [
    {
      displayName: 'Average usage',
      name: 'week-average-usage',
      isActive: false,
    },
    {
      displayName: 'Top utilization',
      name: 'week-top-utilization',
      isActive: false,
    },
    {
      displayName: 'Top booking',
      name: 'week-top-booking',
      isActive: false,
    },
  ];

  pagesMonthly = [
    {
      displayName: 'Average usage',
      name: 'month-average-usage',
      isActive: false,
    },
    {
      displayName: 'Top utilization',
      name: 'month-top-utilization',
      isActive: false,
    },
    {
      displayName: 'Top booking',
      name: 'month-top-booking',
      isActive: false,
    },
  ];

  startPage = '';
}

export default new PowerBiReportState();
