<template>
  <div>
    <Header
      :back="true"
      :route="`/report/${$route.params.page}`"
      class="flex"
      :text="$t('Views.Report.Title')"
      :title="$t('Views.Report.Filters')"
    >
      <h5 class="header-title">
        {{ $t('Views.Report.Filters') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card position="fill">
        <router-link
          :to="`/report/${$route.params.page}/location`"
        >
          <div
            class="settings_link--container"
          >
            <div class="content-container">
              <RoundResourceIcon
                src="/icons/building_nav.svg"
                type="settings reverse"
              />
              <h6 class="setting_link--text">
                {{ $t('Views.Report.LocationFilter') }}
              </h6>
            </div>
            <div class="settings_icon--right">
              {{ ReportModel.allFloorsSelected ? $t('Views.Report.All') : ReportModel.selectedFloors.length }}
              {{ ReportModel.selectedFloors.length > 1 ? $t('Views.Report.Floors') : $t('Views.Report.Floor') }}
              {{ $t('Views.Report.Selected') }}
            </div>
          </div>
        </router-link>
        <router-link
          :to="`/report/${$route.params.page}/resourcetype`"
        >
          <div
            class="settings_link--container"
          >
            <div class="content-container">
              <RoundResourceIcon
                src="/icons/location.svg"
                type="settings reverse"
              />
              <h6 class="setting_link--text">
                {{ $t('Views.Report.ResourceTypeFilter') }}
              </h6>
            </div>
            <div class="settings_icon--right">
              {{ ReportModel.selectedZoneTypes.length === 0 ? $t('Views.Report.All') : ReportModel.selectedZoneTypes.length }}
              {{ ReportModel.selectedZoneTypes.length > 1 ? $t('Views.Report.ZoneTypes') : $t('Views.Report.ZoneType') }}
              {{ $t('Views.Report.Selected') }}
            </div>
          </div>
        </router-link>
        <router-link
          :to="`/report/${$route.params.page}/resource`"
        >
          <div
            class="settings_link--container"
          >
            <div class="content-container">
              <RoundResourceIcon
                src="/icons/desk.svg"
                type="settings reverse"
              />
              <h6 class="setting_link--text">
                {{ $t('Views.Report.ResourceFilter') }}
              </h6>
            </div>
            <div class="settings_icon--right">
              {{ ReportModel.selectedZones.length === 0 ? $t('Views.Report.All') : ReportModel.selectedZones.length }}
              {{ ReportModel.selectedZones.length > 1 ? $t('Views.Report.Zones') : $t('Views.Report.Zone') }}
              {{ $t('Views.Report.Selected') }}
            </div>
          </div>
        </router-link>
        <!-- <router-link
          :to="`/report/${$route.params.page}/equipment`"
        >
          <div
            class="settings_link--container"
          >
            <div class="content-container">
              <RoundResourceIcon
                src="/icons/building_nav.svg"
                type="settings reverse"
              />
              <h6 class="setting_link--text">
                {{ $t('Views.Report.EquipmentFilter') }}
              </h6>
            </div>
          </div>
        </router-link> -->
      </Card>
    </div>
  </div>
</template>

<script>
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import ReportModel from '@/singletons/report.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';

export default {
  components: {
    Header,
    Card,
    RoundResourceIcon,
  },
  data() {
    return {
      ReportModel,
      CompanyState,
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
};

</script>

<style lang="scss" scoped>

.router-link-exact-active
.settings_link--container {
  background: #def3e7;
  border-radius: 0.5rem;
}

.settings_link--container {
  margin: 0.3rem 0;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  &:hover{
    text-decoration: none !important;
    cursor: pointer;
  }
  .content-container{
    display: flex;
    gap:1rem;
  }
}

.setting_link--text{
  margin-top: auto;
  margin-bottom: auto;
}

.settings_icon--right {
  margin: auto 0;
  color: #A6A6A6;
  font-size: 0.8rem;
  font-weight: 500;
}

.filter-resource_container{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-direction: row-reverse;
  margin-bottom: 1rem;
}

</style>
